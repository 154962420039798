import React, { useEffect } from "react";

import { StyledBackgrounds } from "./Backgrounds.styled";
import { WPImage } from "@/components/WPImage/WPImage";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import ParticleImage from "@/components/ParticleImage/ParticleImage";
import { IWPImage } from "@/types/WPImage";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

interface Props {
  pageProps: {
    background: IWPImage;
    product_image: IWPImage;
    particle_images: IWPImage;
  };
}

const Backgrounds = ({ pageProps }: Props) => {
  const randomId = Math.floor(Math.random() * 1000);

  useEffect(() => {
    if (typeof window !== "undefined") {
      //scroll listener
      const handleScroll = () => {
        const scroll = window.scrollY;
        const productImage = document.getElementById(
          randomId.toString()
        ) as HTMLElement;
        if (productImage) {
          productImage.style.transform = `rotate(${scroll / 10}deg)`;
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);
  return (
    <StyledBackgrounds background={pageProps?.background}>
      <div className="main-inner">
        <div className="bkg__container">
          {pageProps?.particle_images && (
            <ParticleImage particleImage={pageProps?.particle_images} />
          )}
          {pageProps?.product_image && (
            <>
              <div className="bkg__product-image" id={randomId.toString()}>
                <WPImage
                  image={pageProps?.product_image}
                  width={"850"}
                  height={"850"}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </StyledBackgrounds>
  );
};

export default Backgrounds;
