import styled from "styled-components";
import {
  theme,
  float,
  popRight1,
  popRight2,
  popRight3,
  popLeft4,
  popLeft5,
  popLeft6,
} from "../../themeConfig";

export const StyledParticleImage = styled.div`
  @media screen and (max-width: 720px) {
    display: none;
  }
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  span {
    transform: scale(1.2) !important;
  }

  #image-1 {
    position: absolute;
    left: -275px;
    top: -400px;
    animation: ${float} 30s linear infinite;
    img {
      object-fit: contain;
    }
  }
  #image-2 {
    position: absolute;
    left: -575px;
    top: -250px;
    animation: ${float} 45s linear reverse infinite;
    img {
      object-fit: contain;
    }
  }
  #image-3 {
    position: absolute;
    left: -375px;
    top: -100px;
    animation: ${float} 65s linear reverse infinite;
    img {
      object-fit: contain;
    }
  }

  #image-4 {
    position: absolute;
    left: -600px;
    top: 100px;
    animation: ${float} 25s linear infinite;
    img {
      object-fit: contain;
    }
  }
  #image-5 {
    position: absolute;
    left: -295px;
    top: 200px;
    animation: ${float} 35s linear infinite;
    img {
      object-fit: contain;
    }
  }
  #image-6 {
    position: absolute;
    right: -275px;
    top: -400px;
    animation: ${float} 30s linear reverse infinite;
    img {
      object-fit: contain;
    }
  }
  #image-7 {
    position: absolute;
    right: -575px;
    top: -250px;
    animation: ${float} 45s linear infinite;
    img {
      object-fit: contain;
    }
  }
  #image-8 {
    position: absolute;
    right: -375px;
    top: -100px;
    animation: ${float} 65s linear reverse infinite;
    img {
      object-fit: contain;
    }
  }
  #image-9 {
    position: absolute;
    right: -600px;
    top: 100px;
    animation: ${float} 25s linear infinite;
    img {
      object-fit: contain;
    }
  }
  #image-10 {
    position: absolute;
    right: -295px;
    top: 200px;
    animation: ${float} 35s linear reverse infinite;
    img {
      object-fit: contain;
    }
  }
`;
export const StyledParticleProduct = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transform: translateY(-73px);
  width: 542px;
  height: 500px;

  span {
    transform: scale(1.2) !important;
  }

  .particle-right {
    animation-delay: 500ms;
    animation-duration: 10s;
    animation-timing-function: cubic-bezier(0.05, 1, 0.16, 0.92);
    animation-fill-mode: both;
  }

  .particle-left {
    animation-delay: 500ms;
    animation-duration: 10s;
    animation-timing-function: cubic-bezier(0.05, 1, 0.16, 0.92);
    animation-fill-mode: both;
  }

  #product-particle1 {
    position: absolute;
    top: 250px;
    right: 90px;
    animation-name: ${popRight1};
  }

  #product-particle2 {
    position: absolute;
    top: 148px;
    right: 108px;
    animation-name: ${popRight2};
  }

  #product-particle3 {
    position: absolute;
    top: 200px;
    right: 100px;
    animation-name: ${popRight3};
  }

  #product-particle4 {
    position: absolute;
    top: 137px;
    left: 177px;
    animation-name: ${popLeft4};
  }

  #product-particle5 {
    position: absolute;
    top: 183px;
    left: 167px;
    animation-name: ${popLeft5};
  }

  #product-particle6 {
    position: absolute;
    top: 300px;
    left: 231px;
    animation-name: ${popLeft6};
  }

  @keyframes pop-right {
    0% {
      opacity: 1;
      transform: translate(0px, 0px) rotate(0deg);
    }
    3% {
      opacity: 1;
      transform: translate(50px, -70px) rotate(40deg);
    }
    90% {
      opacity: 1;
      transform: translate(150px, -100px) rotate(80deg);
    }
    100% {
      opacity: 0;
      transform: translate(160px, -105px) rotate(90deg);
    }
  }
  @keyframes pop-left {
    0% {
      opacity: 1;
      transform: translate(0px, 0px) rotate(0deg);
    }
    3% {
      opacity: 1;
      transform: translate(-50px, -70px) rotate(40deg);
    }
    90% {
      opacity: 1;
      transform: translate(-150px, -100px) rotate(80deg);
    }
    100% {
      opacity: 0;
      transform: translate(-160px, -105px) rotate(90deg);
    }
  }

  @media screen and (max-width: ${theme.breakpoints.big}) {
    transform: translateY(-71px) translateX(-50%);
    left: 50%;
  }

  @media screen and (max-width: ${theme.breakpoints.small}) {
    width: 100%;

    #image-1 {
      left: -72px;
      width: 20px;
    }
    #image-2 {
      left: -172px;
    }
    #image-3 {
      left: -75px;
    }
    #image-4 {
      left: -200px;
      width: 50px;
    }
    #image-5 {
      left: -80px;
    }
    #image-6 {
      right: -150px;
    }
    #image-7 {
      right: -140px;
      width: 30px;
    }
    #image-8 {
      right: -100px;
      width: 20px;
    }
    #image-9 {
      right: -200px;
    }
    #image-10 {
      right: -230px;
      width: 20px;
    }
  }
`;
