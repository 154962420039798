import gsap from "gsap";

export const GsapEffect = ({
  pagePropsLength,
}: {
  pagePropsLength: number;
}) => {
  const slides = Array.from(
    { length: pagePropsLength + 2 },
    (_, i) => `.slide_${i + 1}`
  );
  const sections = Array.from(
    { length: pagePropsLength + 1 },
    (_, i) => `.section${i.toString().padStart(2, "0")}`
  );

  slides.forEach((slide, index) => {
    if (index < sections.length - 1) {
      // Fade in the current slide
      gsap.to(slide, {
        opacity: 1,
        ease: "none",
        scrollTrigger: {
          trigger: sections[index],
          start: "top center",
          end: "center center",
          scrub: true,
        },
      });

      // Fade out the current slide
      gsap.to(slide, {
        opacity: 0,
        ease: "none",
        immediateRender: false,
        scrollTrigger: {
          trigger: sections[index + 1],
          start: "top center",
          end: "center center",
          scrub: true,
        },
      });
    }
  });

  // Ensure the last slide remains visible when scrolling to the last section
  gsap.to(slides[slides.length - 1], {
    opacity: 1,
    ease: "none",
    scrollTrigger: {
      trigger: sections[sections.length - 1],
      start: "top center",
      end: "bottom bottom",
      scrub: true,
    },
  });
};
