import React, { useEffect } from "react";
import { StyledBrandInfo, StyledGsap } from "./Gsap.styled";

import Backgrounds from "../FrontPageSlides/Backgrounds/Backgrounds";
import FrontPageHero2 from "../FrontPageHero2/FrontPageHero2";

import Slide2 from "../FrontPageSlides/Slide2/Slide2";

// GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollSmoother } from "gsap/dist/ScrollSmoother";
import { GsapEffect } from "./GsapEffect";
import { WPImage } from "../WPImage/WPImage";
if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
}

type Props = {
  pageProps: any;
  heroProps: any;
  brandInfoProps: any;
};

const Gsap = ({ pageProps, heroProps, brandInfoProps }: Props) => {
  useEffect(() => {
    console.log("pageProps", pageProps);
    GsapEffect({ pagePropsLength: pageProps.length });
  }, []);

  return (
    <StyledGsap id="gsap-wrapper">
      <div id="images">
        <div
          className="slide_1 slides"
          style={{
            opacity: 1,
            position: "relative",
            visibility: "visible",
          }}
        >
          <Backgrounds pageProps={heroProps[0]} />
        </div>

        <div className="slide_2 slides">
          <Backgrounds pageProps={heroProps[0]} />
        </div>

        {pageProps.map((item: any, index: number) => (
          <div key={index} className={`slide_${index + 3} slides`}>
            <Backgrounds pageProps={item} />
          </div>
        ))}
      </div>

      <section className="section00 section">
        <FrontPageHero2 pageProps={heroProps} />
      </section>

      <section className="section01 section">
        <StyledBrandInfo background={brandInfoProps.brand_background}>
          <div
            style={{
              textAlign: brandInfoProps.center_text ? "center" : "left",
            }}
          >
            <h2
              style={{
                textAlign: brandInfoProps.center_text ? "center" : "left",
              }}
            >
              {brandInfoProps.brand_title}
            </h2>
            <div
              style={{
                zIndex: 2,
                position: "relative",
              }}
              className="text"
              dangerouslySetInnerHTML={{ __html: brandInfoProps.brand_text }}
            />
            <div className="imageCont">
              <WPImage image={brandInfoProps.brand_image} />
            </div>
          </div>
        </StyledBrandInfo>
      </section>

      {pageProps.map((item: any, index: number) => {
        const sectionClass = `section0${index + 2} section`;

        return (
          <section key={index} className={`${sectionClass} sectionItem`}>
            <Slide2 pageProps={item} />;
          </section>
        );
      })}
    </StyledGsap>
  );
};

export default Gsap;
